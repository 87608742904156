import React from 'react';
import { PulseLoader } from 'react-spinners';

type LoaderProps = {
  full?: boolean;
  half?: boolean;
  hero?: boolean;
  txt?: string;
};

const Loader: React.FC<LoaderProps> = ({ full, half, hero, txt }) => (
  <div
    className={`page-loading ${half && 'min-vh-50'} ${full && 'min-vh-100'} ${
      hero && 'hero_loader'
    }`}
  >
    <PulseLoader size={20} color={'#cbcbcb'} loading />
    {txt && <p>{txt}</p>}
  </div>
);

export default Loader;
