import { useAsync } from 'react-async-hook';
import firebase from 'firebase/compat/app';
import { db } from '../middleware/firebase';
import { priceIva, getRegularPrice } from '../middleware/iva';
import { Xipm } from '../graphql/types-and-hooks';
import { formatNumber } from '../middleware/common-functions';

// function sleep(ms: number) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

export type Filters = {
  types?: string[];
  grpsbi?: string[];
  priceRanges?: [number?, number?][] | [];
  lines?: string[];
  brands?: string[];
  ids?: string[];
  limit?: number;
  pagination?: number;
  urlTypes?: string[];
  size?: string;
  promotions?: boolean;
  category?: string;
  altoValor?: boolean;
  additionalAttributes?: string;
  xiazId?: number | string;
  order?: 'price' | 'altoValor';
};
let has_data: boolean = false;
let filter: boolean = true;

export type Product = Pick<
  Xipm,
  'id' | 'idpart' | 'descp1' | 'groups' | 'types' | 'grpsbi' | 'zonePrice'
> & { linea: Pick<Xipm['linea'], 'id' | 'idtipo' | 'descrp'> } & {
  price: string;
  stock: boolean;
  icon?: string;
  price_num: number;
  price_regular: any;
  aliasd: string;
  zonePriceObjByXiazId: {
    [K in string]: {
      price: number;
    };
  };
};

export type IconItem = {
  id: string;
  name: string;
  from: firebase.firestore.Timestamp;
  addType: 'cats' | 'products' | 'brands';
  cats?: string[];
  idparts?: string[];
  brands?: string[];
  img: string;
};

export async function getProducts(filters: Filters = {}) {
  const iconsRef = db.collection('icons');
  const icons: IconItem[] = [];
  const start = new Date();
  const snapshot = await iconsRef.where('valid', '>=', start).get();
  snapshot.forEach((doc) => {
    const icon_data = doc.data() as IconItem;
    icon_data.id = doc.id;
    if (
      typeof icon_data.from === 'undefined' ||
      icon_data.from.toDate() >= start
    ) {
      return;
    }
    icons.push(icon_data);
  });

  try {
    const {
      // types = [],
      grpsbi = [],
      lines = [],
      brands = [],
      priceRanges = [],
      ids = [],
      limit = 80,
      pagination = 1,
      urlTypes = [],
      size = '',
      additionalAttributes = '',
      promotions = false,
      category = false,
      altoValor = false,
      xiazId = 1,
      order = 'price',
    } = filters;
    const start_at = (pagination - 1) * limit;
    //console.log("useProducts.ts:91 | start_at", start_at);
    let products = null as any as firebase.firestore.Query<Product>;

    switch (order) {
      case 'price':
        products = db
          .collection('products')
          .orderBy('stock', 'desc')
          .orderBy('has_images', 'desc')
          .orderBy('zone1_price', 'desc')
          .limit(limit) as any as firebase.firestore.Query<Product>;
        break;

      case 'altoValor':
        products = db
          .collection('products')
          .orderBy('altoValor', 'desc')
          .orderBy('stock', 'desc')
          .orderBy('has_images', 'desc')
          .limit(limit) as any as firebase.firestore.Query<Product>;
        break;

      default:
        products = db
          .collection('products')
          .limit(limit) as any as firebase.firestore.Query<Product>;
        break;
    }

    // priceRanges.forEach(([min, max]) => {
    //   if (min) {
    //     products = products.where(`zonePriceObjByXiazId.${xiazId}.price`, ">=", min)
    //   }
    //   if (max) {
    //     products = products.where(`zonePriceObjByXiazId.${xiazId}.price`, "<=", max);
    //   }
    // })

    if (urlTypes.length) {
      urlTypes.forEach((elem, index) => {
        if (elem) {
          products = products.where(`grpsbiObj.${index}`, '==', elem);
        }
      });
    }
    if (size) products = products.where('grpsbiObj.3', '==', size);

    if (promotions) {
      has_data = true;
      products = products
        .where('has_promotion', '==', true)
        .orderBy('grpsbiStr_lvl2');
    }
    if (category) {
      has_data = true;
      products = products
        .where('category', '==', category)
        .orderBy('grpsbiStr_lvl2');
    }
    if (altoValor) {
      has_data = true;
      products = products.where('altoValor', '==', true);
      if (promotions) {
        products.orderBy('grpsbiStr_lvl2');
      }
    }

    if (additionalAttributes)
      products = products.where('grpsbiObj.4', '==', additionalAttributes);

    if (grpsbi.length && !(ids && ids.length)) {
      has_data = true;
      filter = false;
      products = products.where('grpsbi', 'array-contains-any', grpsbi);
    } else if (grpsbi.length) {
      products = products.where('grpsbi', 'array-contains', grpsbi[0]);
    }

    if (ids && ids.length) {
      has_data = true;
      products = products.where('id', 'in', ids);
    }

    if (brands.length) {
      has_data = true;
      products = products.where('aliasd', '==', brands[0]);
    }
    if (has_data === false) return false;
    if (pagination > 1) {
      const first: any = products.limit(limit * pagination);
      const snapshot = await first.get();
      // Get the last document
      const last = snapshot.docs[snapshot.docs.length - 1];
      products = products.startAfter(last);
    }
    const docs = (await products.limit(limit).get()).docs;
    ///return if false
    if (!docs.length) return false;

    // await sleep(2000);
    // console.log({ return_products: docs.map((doc) => doc.data()) });
    const return_products = docs
      .map((doc) => {
        const product = doc.data();
        product.icon = renderIcon(product, icons);
        product.price_num = priceIva(product.zonePrice, product.price);
        product.price = formatNumber(product.price_num);
        product.price_regular = getRegularPrice(
          product.zonePrice,
          product.price_num
        );

        return product;
      })
      .filter((prod) => typeof prod.price_num === 'number');
    //.filter((doc) => !('has_images' in doc));
    //.sort((a, b) => (a.descp1 > b.descp1 ? 1 : -1));
    /// these filters cannot be added to the query above
    /// consult (the docs)[https://firebase.google.com/docs/firestore/query-data/queries]
    return return_products
      .filter(
        (product) =>
          !priceRanges.length ||
          priceRanges
            .map(([min, max]) => {
              const price = Number(product.price_num);
              if (!price) return false;
              if (min && max) {
                if (price > min && price < max) {
                  return true;
                } else {
                  return false;
                }
              } else if (min || max) {
                if ((min && price > min) || (max && price < max)) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            })
            .filter(Boolean).length
      )
      .filter(
        (product) => !lines.length || lines.includes(product.linea.descrp)
      )
      .filter(
        (product) =>
          !grpsbi.length ||
          grpsbi.some((elem) => product.grpsbi?.includes(elem))
      )
      .filter(
        (product) =>
          !(brands.length > 1) ||
          !product.aliasd ||
          brands.includes(product.aliasd)
      );
  } catch (error) {
    console.error('Error', error);
    return [];
  }
}

export default function useProducts(filters: Filters = {}) {
  const location = JSON.parse(localStorage.getItem('location') || '{}');
  if (!location.id || location.id === '0' || location.id === 0) {
    location.id = '1';
  }

  //console.log('useProducts.ts:245 | location.id type',location.id,typeof location.id);

  const products = useAsync(
    () => getProducts({ ...filters, xiazId: location.id }),
    [Object.values(filters).map(String).filter(Boolean).sort().join('') || '']
  );

  return products;
}

export function renderIcon(
  product: Product,
  icons: IconItem[]
): string | undefined {
  //search by id
  let icon = icons.find(
    (icon) =>
      icon.idparts && product.idpart && icon.idparts.includes(product.idpart)
  );
  if (icon) {
    return icon.img;
  }
  //search by brand
  icon = icons.find(
    (icon) => icon.brands && icon.brands.includes(product.aliasd)
  );
  if (icon) {
    return icon.img;
  }
  //search by cat
  if (product.grpsbi) {
    icon = icons.find((icon) => {
      if (!icon.cats) return false;
      return icon.cats.find((cats_str) => {
        const last = cats_str.split(' > ').pop();
        //console.log('useProducts.ts:245 | last', last);
        //console.log('useProducts.ts:246 | product.grpsbi', product.grpsbi);
        if (!product.grpsbi || !last) {
          return false;
        }
        return product.grpsbi.includes(last);
      });
    });
    if (icon) {
      return icon.img;
    }
  }
  return undefined;
}
