import React from 'react';
import useProductTags from '../../../hooks/useProductTags';
import { CheckboxLink } from '../lists/filters';
import { replaceWords } from '../products/list';

type FiltersProps = {
  selected: string;
};
const Filters: React.FC<FiltersProps> = ({ selected }) => {
  const { result: productsTags } = useProductTags();
  if (!productsTags) {
    return <div>Loading...</div>;
  }

  return (
    <div className="">
      <h3 className="underlined my-4">Categorías</h3>
      {Object.keys(productsTags).map((tag) => {
        const url = replaceWords(tag);
        return (
          <CheckboxLink
            key={`filtos_prmos_${tag}`}
            to={selected === url ? '/eco' : `/eco/${replaceWords(url)}`}
            isSelected={selected === url}
            type={'category'}
            value={tag}
          />
        );
      })}
    </div>
  );
};

export default Filters;
