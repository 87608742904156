import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/font-awesome.css';
import './css/app.css';

import { AuthContext } from './context/auth-context';

// Page Components
import Home from './components/pages/home';
import Product from './components/pages/product';
import CartPage from './components/pages/cart';
import Checkout from './components/pages/checkout';
import ThankyouPage from './components/pages/thankyou';
import ProductList from './components/pages/products/list';
import AltoValor from './components/pages/alto_valor';
import PromotionsPage from './components/pages/promotions';
import EcoPage from './components/pages/eco';
import BrandsPage from './components/pages/brands';
import AccountPage from './components/pages/account';
import ReviewsPage from './components/pages/reviews';
import LoginPage from './components/pages/login';
import ForgotPage from './components/pages/forgot';
import SignupPage from './components/pages/signup';
import Visualizer from './components/pages/visualizer';
import StaticPage from './components/pages/static_pages';
import Sucursales from './components/pages/sucursales';

import ScrollToTop from './middleware/scrollTop';
import VestaProvider from './middleware/vesta';
import * as serviceWorker from './serviceWorker';
import Layouts from './components/layouts';
import Page404 from './components/pages/page404';

const App = () => {
  const page = window.location.host;
  const authContext = useContext(AuthContext);
  const graph_url = process.env.REACT_APP_GRAPH_URL;
  let title = 'Mathasa';
  const api = new ApolloClient({
    uri: graph_url,
    request: (operation) => {
      const token = process.env.REACT_APP_GRAPH_TOKEN;
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      });
    },
  });

  switch (page) {
    case 'mathasa.mx':
      title = 'Mathasa';
      break;
    case 'decasajuarez.com':
      title = 'Decasa Juárez';
      break;
    case 'decasa.mx':
      title = 'Decasa Hermosillo';
      break;
    case 'idecasa.mx':
      title = 'Idecasa';
      break;
    case 'designmathasa.mx':
      title = 'Design Center by Mathasa';
      break;

    default:
      title = 'Mathasa';
  }
  return (
    <BrowserRouter>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content="Especialistas en acabados para la construcción"
        />
      </Helmet>
      <ScrollToTop>
        <ApolloProvider client={api}>
          <div className={`${page.replace('.mx', '').replace('.com', '')}`}>
            <Layouts>
              <Switch>
                <Route
                  path="/lista/:primary/:secondary/:tertiary/:product_title/:product_id"
                  component={Product}
                />
                <Route
                  path="/lista/:primary?/:secondary?/:tertiary?"
                  component={ProductList}
                />
                <Route path="/product/:product_id" component={Product} />
                <Route
                  path="/alto_valor/:grpsbi?/:pagination?"
                  component={AltoValor}
                />
                <Route path="/cart" component={CartPage} />
                <Route path="/checkout" component={Checkout} />
                <Route path="/gracias/:order_id" component={ThankyouPage} />
                <Route path="/visualiza" component={Visualizer} />
                <Route exact path="/sucursales" component={Sucursales} />
                <Route
                  path="/promociones/:grpsbi?/:pagination?"
                  component={PromotionsPage}
                />
                <Route path="/eco/:grpsbi?/:pagination?" component={EcoPage} />
                <Route path="/marca/:brand_name" component={BrandsPage} />
                <Route path="/info/:post_name" component={StaticPage} />
                <Route
                  path="/cuenta/:section/:item_id?"
                  component={authContext.isAuth ? AccountPage : LoginPage}
                />
                <Route path="/valorar/:product_id?" component={ReviewsPage} />
                <Route
                  path="/login"
                  component={authContext.isAuth ? AccountPage : LoginPage}
                />
                <Route
                  path="/recuperar"
                  component={authContext.isAuth ? AccountPage : ForgotPage}
                />
                <Route
                  path="/signup"
                  component={authContext.isAuth ? AccountPage : SignupPage}
                />
                <Route path="/store" component={Home} />
                <Route exact path="/" component={Home} />
                <Route component={Page404} />
              </Switch>
            </Layouts>
          </div>
        </ApolloProvider>
      </ScrollToTop>
      {authContext.fingerprint && (
        <VestaProvider fingerprint={authContext.fingerprint} />
      )}
    </BrowserRouter>
  );
};

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
