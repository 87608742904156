import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

import useProducts from '../../../hooks/useProducts';
import Loader from '../../common/loader';
import Products from './products';
import Filters from './filters';
import EmptyJumbotron from '../../common/emptyJumbotron';
import { addProductCart } from '../../../middleware/cartFunctions';
import { AddProductModal } from '../../common/modals/modals';
import { isDesign } from '../../../middleware/common-functions';

const EcoPage = () => {
  const is_design = isDesign();
  const [modal, setModal] = useState(false);
  const [filters, setFilters] = useState([]);
  const [addedProduct, setAddedProduct] = useState(false);
  const { pagination = 1, grpsbi = 'todo' } = useParams();
  const { result: products, loading } = useProducts({
    category: 'eco',
    limit: 50,
    pagination,
    grpsbi: filters,
    orderByStock: false,
    altoValor: is_design,
  });
  useEffect(() => {
    if (grpsbi !== 'todo') {
      setFilters([grpsbi]);
    } else {
      setFilters([]);
    }
  }, [grpsbi]);

  const promptAddProduct = async (ev) => {
    const product_id = ev.currentTarget.dataset.product_id;
    const product = products.find((p) => p.id === product_id);

    const product_amount = document.getElementById(
      `cant_input_list_${product_id}`
    ).value;
    product.amount = product_amount;
    setAddedProduct(product);
    await addProductCart({ product_amount, product_id, product_data: product });
    setModal(true);
  };
  const hideModal = () => {
    setModal(false);
  };

  return (
    <div className="container-fluid  first-container">
      <div className="product_list_section">
        <div className="row">
          <img
            src="https://cdn.mathasa.mx/banners/productos-ecologicosbanner.jpg"
            alt="banner eco friendly category"
            className="img-fluid"
          />
          <div className="col-md-2 xs_hidden">
            <Filters selected={grpsbi} />
          </div>
          <div className="col-md-10 ">
            <h3 className="underlined my-4">Eco Friendly</h3>
            {products && products.length ? (
              <Products
                productsList={products}
                addProductCart={promptAddProduct}
              />
            ) : loading ? (
              <Loader />
            ) : (
              <EmptyJumbotron
                icon="fas fa-shower"
                text="Selecciona otra categoría"
              />
            )}
            {products && products.length > 20 ? (
              <PaginationBtn pagination={pagination} filter={grpsbi} />
            ) : null}
          </div>
        </div>
      </div>
      <AddProductModal
        active={modal}
        toggle={hideModal}
        product_title={addedProduct && addedProduct.descp1}
        type={
          addedProduct && addedProduct.xium
            ? addedProduct && addedProduct.xium.descrp
            : 'pz'
        }
        neddedProducts_ids={addedProduct && addedProduct.ptrl}
        relatedTypes={addedProduct ? addedProduct.grpsbi[2] : null}
        product_amount={addedProduct.amount}
      />
    </div>
  );
};

const PaginationBtn = ({ pagination = 1, filter = 'todo' }) => {
  pagination = parseInt(pagination);
  const pagination_btn = [];
  const steps = 3;
  for (let i = pagination - steps; i <= pagination + steps; i++) {
    if (i < 1) {
      continue;
    }
    pagination_btn.push(
      <li
        className={`page-item ${pagination === i && 'active'}`}
        key={`pagination_${i}`}
      >
        <Link className="page-link" to={`/eco/${filter}/${i}`}>
          {i}
        </Link>
      </li>
    );
  }

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center">
        {pagination > 1 && (
          <li className="page-item disabled">
            <Link
              className="page-link"
              to={`/eco/${pagination - 1}`}
              tabindex="-1"
            >
              Atrás
            </Link>
          </li>
        )}
        {pagination_btn.map((item, key) => item)}
        <li className="page-item">
          <Link className="page-link" to={`/eco/${pagination + 1}`}>
            Siguiente
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default EcoPage;
